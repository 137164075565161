.date-picker-calendar-dark {
  background: #203147;
  border: 1px solid #203147;
  color: #d6e8ff;
}

.date-picker-calendar-light {
  background: #ffffff;
  border: none;
  box-shadow: 0px 4px 20px rgba(9, 18, 29, 0.3);
  color: #000000;
}

.react-datepicker__header {
  text-align: center;
  background-color: inherit;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 13px 6px;
  position: relative;
}

.react-datepicker__day--in-range {
  color: #ffffff !important;
}

.react-datepicker__day {
  color: inherit;
}

.date-picker-calendar-light .react-datepicker__day :hover {
  border-radius: 0.3rem;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.date-picker-calendar-dark .react-datepicker__day :hover {
  border-radius: 0.3rem;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background: none !important;
  color: #1877f2 !important;
  border-radius: none !important;
  border-bottom: 2px solid #1877f2;
}

.date-picker-calendar-dark
  .react-datepicker__header
  .react-datepicker__day-name {
  color: #d6e8ff;
}

.date-picker-calendar-light
  .react-datepicker__header
  .react-datepicker__day-name {
  color: #000000;
}
